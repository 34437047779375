import React from 'react'
import { Tallos } from '../types/tallos'

type UseTallosReturn = Tallos['chat'] & {
  setStyle?: (style: React.CSSProperties) => void
}

export type TallosContext = {
  [key in keyof UseTallosReturn]: UseTallosReturn[key]
}
export const TallosContext = React.createContext<TallosContext>(null)

export const TallosContextProvider = (props: {
  children: React.ReactNode
}): JSX.Element => {
  const [style, setStyle] = React.useState<React.CSSProperties>(null)
  const [hideTallos, setHideTallos] = React.useState<boolean>(false)

  const show = () => {
    setStyle(style)
    setHideTallos(false)
  }
  const hide = () => {
    setStyle(null)
    setHideTallos(true)
  }

  const setTallosChatCssProperties = (
    octachat: HTMLElement,
    style: React.CSSProperties
  ) => {
    if (!style || !octachat) return
    Object.entries(style).forEach(
      ([key, value]) => (octachat.style[key] = value)
    )
  }
  const clearTallosChatCssProperties = (
    octachat: HTMLElement,
    style: React.CSSProperties
  ) => {
    if (!style || !octachat) return
    Object.entries(style).forEach(([key]) => (octachat.style[key] = null))
  }

  React.useEffect(() => {
    const tallosWidget = document?.getElementById('megasac-button')
    const tallosWidgetPopup = document?.getElementById('megasac-info')
    setTallosChatCssProperties(tallosWidget, style)
    setTallosChatCssProperties(tallosWidgetPopup, style)
    if (tallosWidget?.parentElement) {
      tallosWidget.parentElement.hidden = hideTallos
    }
    return () => {
      clearTallosChatCssProperties(tallosWidget, style)
      clearTallosChatCssProperties(tallosWidgetPopup, style)
      if (tallosWidget?.parentElement) {
        tallosWidget?.parentElement?.removeAttribute?.('hidden')
      }
    }
  }, [style, hideTallos])

  const contextValue: TallosContext = { show, hide, setStyle }

  return (
    <TallosContext.Provider value={contextValue}>
      {props.children}
    </TallosContext.Provider>
  )
}

export default TallosContextProvider
