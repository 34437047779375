import { useToasts } from 'react-toast-notifications'

export type ToastType = {
  message: string
  timeout?: number
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useToast() {
  const { addToast } = useToasts()
  function showError({ message, timeout = 5000 }: ToastType): void {
    const printableText =
      typeof message === 'string' ? message : JSON.stringify(message)
    return addToast(printableText, {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: timeout,
    })
  }
  function showSuccess({ message, timeout = 5000 }: ToastType): void {
    const printableText =
      typeof message === 'string' ? message : JSON.stringify(message)
    return addToast(printableText, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: timeout,
    })
  }
  function showInfo({ message, timeout = 5000 }: ToastType): void {
    const printableText =
      typeof message === 'string' ? message : JSON.stringify(message)
    return addToast(printableText, {
      appearance: 'info',
      autoDismiss: true,
      autoDismissTimeout: timeout,
    })
  }
  return { showError, showSuccess, showInfo }
}
