import AuthContextProvider from 'contexts/auth-context'
import { TallosContextProvider } from 'features/tallos'
import type { AppProps /*, AppContext */ } from 'next/app'
import React from 'react'
import TagManager from 'react-gtm-module'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ToastProvider } from 'react-toast-notifications'
import 'styles/rich-text.css'
import 'tailwindcss/tailwind.css'

export const queryClient = new QueryClient()

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  // Google Tag Manager
  // https://github.com/vercel/next.js/discussions/15518#discussioncomment-352804
  const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY
  React.useEffect(() => {
    TagManager.initialize({ gtmId: GTM_KEY })
  }, [])

  const [queryClientState] = React.useState(() => new QueryClient())

  return (
    <QueryClientProvider client={queryClientState}>
      <Hydrate state={pageProps.dehydratedState}>
        <TallosContextProvider>
          <ToastProvider>
            <AuthContextProvider>
              <Component {...pageProps} />
            </AuthContextProvider>
          </ToastProvider>
        </TallosContextProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp
