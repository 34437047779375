import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'
import { SuccessType } from 'types/success-type'

// eslint-disable-next-line @typescript-eslint/ban-types
export type ParamsType = void
export type ResponseType = SuccessType
type ServiceType = () => Promise<ResponseType>

const logoutService: ServiceType = async () => {
  try {
    const { data } = await api.post('/students/auth/logout')
    return { message: 'Logout executado com sucesso', data }
  } catch (error) {
    const defaultMessage = 'Erro ao efetuar logout'
    throw handleError({ error, defaultMessage })
  }
}

export default logoutService
