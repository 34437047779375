import { ErrorType } from 'types/error-type'

export const onErrorMiddleware =
  <TPayload = any>(
    onError: (error: ErrorType<TPayload>) => void,
    signOut: () => void
  ) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (error: any): void => {
    if (error.error === 'invalid_token') {
      signOut()
      onError?.(error)
      setTimeout(() => {
        window.location.reload()
      }, 3000)
      return
    }
    onError?.(error)
  }
