import { useAuthContext } from 'contexts/auth-context'
import { onErrorMiddleware } from 'helpers/on-error-middleware'
import { useMutation } from 'react-query'
import logoutService, {
  ParamsType,
  ResponseType,
} from 'services/authentication/logout-service'
import { MutateHookType } from 'types/mutate-hook-type'

type HookType = MutateHookType<ParamsType, ResponseType>

export const useLogout: HookType = (options) => {
  const { signOut } = useAuthContext()
  const mutation = useMutation(logoutService, {
    ...options,
    onError: onErrorMiddleware(options?.onError, signOut),
  })
  const { data, error, isLoading, mutate: fetch } = mutation
  return { data, error, isLoading, fetch }
}
