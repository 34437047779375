import * as Sentry from '@sentry/nextjs'
import { useLogout } from 'api-hooks/authentication/use-logout'
import CookieKeys from 'constants/cookie-keys'
import { useToast } from 'hooks/use-toast'
import JwtDecode from 'jwt-decode'
import React from 'react'
import { useCookies } from 'react-cookie'
import { UserType } from 'types/user-type'

export type AuthContextProps = {
  children: React.ReactNode
}

export type UseAuthProps = {
  user?: UserType
  isLoading?: boolean
  signOut: () => void
  setUser: (newUser: UserType) => void
  setUserByAccessToken: (accessToken: string) => void
}

export const AuthContext = React.createContext<UseAuthProps>({
  signOut: () => null,
  setUser: () => null,
  setUserByAccessToken: () => null,
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AuthContextProvider = ({ children }: AuthContextProps) => {
  const [user, setUser] = React.useState<UserType | null>()
  const [isLoading, setIsLoading] = React.useState(true)
  const [cookies, , removeCookie] = useCookies([CookieKeys.ACCESS_TOKEN])
  const { showError } = useToast()
  // const router = useRouter()
  const { fetch: signOut } = useLogout({
    onError: ({ message }) => {
      showError({ message }),
        removeCookie(CookieKeys.ACCESS_TOKEN, {
          path: '/',
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
      setUser(null)
    },
    onSuccess: () => {
      setUser(null)
      removeCookie(CookieKeys.ACCESS_TOKEN, {
        path: '/',
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      })
    },
  })
  const setUserByAccessToken = (accessToken: string) => {
    try {
      if (accessToken) {
        const decodedUser = JwtDecode(accessToken) as UserType
        decodedUser['accessToken'] = accessToken
        setUser(decodedUser)
        Sentry.setUser(user)
      }
    } catch (error) {
      const message = error?.message || 'Erro ao decodificar token de acesso'
      showError({ message })
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    const accessToken = cookies[CookieKeys.ACCESS_TOKEN]
    setUserByAccessToken(accessToken)
  }, [cookies])

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        setUser,
        setUserByAccessToken,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthContext = () => React.useContext(AuthContext)
