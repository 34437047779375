import { ErrorType } from 'types/error-type'

export type HandleErrorParamsType<TPayload = any> = {
  error: any
  defaultMessage: string
  payload?: TPayload
}

const handleError = <TPayload = any>(
  params: HandleErrorParamsType<TPayload>
): ErrorType => {
  const { error, defaultMessage, payload } = params
  const statusCode = error?.response?.data?.statusCode
  const rawMessage = error?.response?.data?.message || error?.message
  const message = Array.isArray(rawMessage) ? rawMessage[0].message : rawMessage
  const errorText = error?.response?.data?.error
  const result: ErrorType<TPayload> = {
    error: errorText,
    statusCode,
    message: message ?? defaultMessage,
    payload,
  }
  return result
}

export default handleError
